export default {
  /* ********* 0 ******* */
  0: {
    image: {
      size: { format: 'A6', portrait: true },
      bleedOrTrim: 0,
      crop_marks: true,
      crop_marks_color: '#FF0000',
      pngResolution: 300,
      qrPngSize: { width: 300, height: 300, unit: 'px' },
    },
    content: [
      {
        idx: 'zero',
        name: 'QR Code',
        selected: false,
        qr: {
          margin: 2,
          tag: {
            attach: true, prefix: true, prefix_short: false, font_size: 7,
          },
          style: { finder_frame: 'default', finder_eye: 'default', point: 'default' },
        },
        color: { fill: '#ffffff', stroke: '#000000', fillOpacity: 1 },
        transform: {
          angle: 0, x: 0, y: 0, scale: 50,
        },
        frame: { active: false, width: 1, offset: 0 },
      },
      {
        idx: 'background',
        name: 'Background',
        selected: false,
        background: { pattern: 'none' },
        transform: null,
        savedTransform: {
          angle: 0,
          x: 0,
          y: 0,
          scale: 41,
        },
        color: { fill: '#FFFFFF' },
        savedColor: {
          fill: '#FFFFFF',
          stroke: '#000000',
          strokeWidth: 10,
          opacity: 0.5,
        },
      },
    ],
  },
  /* ***************** */
  1: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circles-solid',
            finder_frame: 'square-ring',
          },
          margin: 1,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#ffffff',
          stroke: '#000000',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 25,
          y: 5,
          angle: 0,
          scale: 34,
        },
      },
      {
        idx: 'lofkscbj',
        txt: {
          value: '- ONLINE-',
          font: {
            family: 'Playfair Display', size: '16', style: 'normal', weight: '400',
          },
        },
        name: '- ONLINE-',
        type: 'txt',
        color: {
          fill: '#f25269',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 25,
          y: -21,
          angle: 0,
          scale: 31,
        },
      },
      {
        idx: 'loedc381',
        txt: {
          value: 'PHONE: 62278919',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: 'PHONE: 62278919',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -32,
          y: 45,
          angle: 0,
          scale: 25,
        },
      },
      {
        idx: 'lnkkjuak',
        txt: {
          value: 'MENU',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '700',
          },
        },
        name: 'MENU',
        type: 'txt',
        color: {
          fill: '#f25268',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 25,
          y: -28,
          angle: 0,
          scale: 33,
        },
      },
      {
        idx: 'lnkn83i2',
        txt: {
          value: 'VIEW OUR',
          font: {
            size: '16', style: 'normal', family: 'Playfair Display', weight: '400',
          },
        },
        name: 'VIEW OUR',
        type: 'txt',
        color: {
          fill: '#f25268',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 25,
          y: -36,
          angle: 0,
          scale: 33,
        },
      },
      {
        idx: 'lnlmjbog',
        txt: {
          value: 'SCAN ME',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '400',
          },
        },
        name: 'SCAN ME',
        type: 'txt',
        color: {
          fill: '#f25268',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 25,
          y: 23,
          angle: 0,
          scale: 24,
        },
      },
      {
        idx: 'lo32lanv',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#000000',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 161,
          y: 10,
          angle: 0,
          scale: 322,
          fillNotFit: false,
        },
      },
      {
        idx: 'lo32hbyj',
        img: {
          file_name: 'jar',
          isUserMedia: false,
        },
        name: 'jar',
        type: 'img',
        color: {
          fill: '#000000',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -67,
          y: -2,
          angle: 0,
          scale: 152,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#e0dbd5',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701428871587,
  },
  /* ***************** */
  2: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circle',
            finder_frame: 'ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#3d2800',
          stroke: '#ceba8a',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 0,
          y: 22,
          angle: 0,
          scale: 40,
        },
      },
      {
        idx: 'lnknjuyd',
        txt: {
          value: 'Digital menu',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '700',
          },
        },
        name: 'Digital menu',
        type: 'txt',
        color: {
          fill: '#3d2800',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -7,
          angle: 0,
          scale: 57,
        },
      },
      {
        idx: 'lnbqaj6i',
        txt: {
          value: 'SCAN THE QR CODE ',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '700',
          },
        },
        name: 'SCAN THE QR CODE ',
        type: 'txt',
        color: {
          fill: '#3d2800',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 5,
          angle: 0,
          scale: 40,
        },
      },
      {
        idx: 'lnlq2ver',
        img: {
          file_name: 'shape90',
          isUserMedia: false,
        },
        name: 'shape90',
        type: 'img',
        color: {
          fill: '#ceba8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -17,
          angle: 180,
          scale: 77,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlqgc7q',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 13,
          angle: 0,
          scale: 77,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnligckf',
        txt: {
          value: 'More comfortable, faster, and user friendly',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'More comfortable, faster, and user friendly',
        type: 'txt',
        color: {
          fill: '#3d2800',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 45,
          angle: 0,
          scale: 69,
        },
      },
      {
        idx: 'lnlhp0p1',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhnmhx',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 6,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhna58',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 3,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhm8li',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -3,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhgb3m',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#cfbb8a',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -6,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnkjgs6d',
        txt: {
          value: 'RESTAURANT NAME',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'RESTAURANT NAME',
        type: 'txt',
        color: {
          fill: '#cfbb8a',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -37,
          angle: 0,
          scale: 69,
        },
      },
      {
        idx: 'lnlh6iqy',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#ceba8a',
          stroke: '#ecbca1',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 20,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 35,
          angle: 0,
          scale: 77,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#3d2800',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#23293f',
          stroke: '#c8af97',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701428104190,
  },
  /* ***************** */
  3: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'default',
            finder_frame: 'square-ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#d95920',
          stroke: '#025356',
          opacity: 1,
          fillOpacity: 1,
        },
        frame: {
          width: 7,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 0,
          y: 7,
          angle: 0,
          scale: 36,
        },
      },
      {
        idx: 'lnllg919',
        txt: {
          value: 'More comfortable, faster, and user friendly',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'More comfortable, faster, and user friendly',
        type: 'txt',
        color: {
          fill: '#e36c35',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 40,
          angle: 0,
          scale: 86,
        },
      },
      {
        idx: 'lnbq3mhl',
        txt: {
          value: "LET'S ",
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: "LET'S ",
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -24,
          y: -38,
          angle: 0,
          scale: 40,
        },
      },
      {
        idx: 'lnlkyof6',
        txt: {
          value: 'DRINK',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'DRINK',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -28,
          y: -29,
          angle: 0,
          scale: 31,
        },
      },
      {
        idx: 'lnkimcbe',
        txt: {
          value: 'SCAN ME',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'SCAN ME',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 1,
          y: -8,
          angle: 0,
          scale: 17,
        },
      },
      {
        idx: 'lnbqaj6i',
        txt: {
          value: 'TO VIEW OUR MENU',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'TO VIEW OUR MENU',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 23,
          angle: 0,
          scale: 31,
        },
      },
      {
        idx: 'lnll1fbw',
        img: {
          file_name: 'drink',
          isUserMedia: false,
        },
        name: 'drink',
        type: 'img',
        color: {
          fill: '#025356',
          stroke: '#d95a20',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 6,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: true,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 2,
          angle: 0,
          scale: 113,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#025355',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701427848337,
  },
  /* ***************** */
  4: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circle',
            finder_frame: 'ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#a5d61f',
          stroke: '#020401',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 0,
          y: -1,
          angle: 0,
          scale: 39,
        },
      },
      {
        idx: 'lnllwbc3',
        img: {
          file_name: 'shape95',
          isUserMedia: false,
        },
        name: 'shape95',
        type: 'img',
        color: {
          fill: '#a5d620',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 94,
          y: 60,
          angle: 0,
          scale: 193,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnkn83i2',
        txt: {
          value: 'Scan the QR code with your phone',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'Scan the QR code with your phone',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 28,
          angle: 0,
          scale: 77,
        },
      },
      {
        idx: 'lnkn73il',
        txt: {
          value: 'Order here',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '700',
          },
        },
        name: 'Order here',
        type: 'txt',
        color: {
          fill: '#a5d620',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 19,
          angle: 0,
          scale: 27,
        },
      },
      {
        idx: 'lnkn41hu',
        txt: {
          value: 'Your Logo',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '400',
          },
        },
        name: 'Your Logo',
        type: 'txt',
        color: {
          fill: '#050505',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -43,
          angle: 0,
          scale: 15,
        },
      },
      {
        idx: 'lnkkjuak',
        txt: {
          value: 'MENU',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '700',
          },
        },
        name: 'MENU',
        type: 'txt',
        color: {
          fill: '#a5d620',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -20,
          angle: 0,
          scale: 39,
        },
      },
      {
        idx: 'lnllpmoc',
        img: {
          file_name: 'shape95',
          isUserMedia: false,
        },
        name: 'shape95',
        type: 'img',
        color: {
          fill: '#a5d620',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -92,
          y: -52,
          angle: 0,
          scale: 170,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#edf5d0',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1700939913639,
  },
  /* ***************** */
  5: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'default',
            finder_frame: 'square-ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#ffffff',
          stroke: '#430094',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 12,
          active: true,
          offset: 0,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -2,
          angle: 0,
          scale: 47,
        },
      },
      {
        idx: 'lnlkndek',
        txt: {
          value: '101 Market Street, San Francisco, CA',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: '101 Market Street, San Francisco, CA',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -15,
          y: 43,
          angle: 0,
          scale: 57,
        },
      },
      {
        idx: 'lnlkludc',
        txt: {
          value: 'PHONE: 415-555-1212',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: 'PHONE: 415-555-1212',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 32,
          y: 43,
          angle: 0,
          scale: 28,
        },
      },
      {
        idx: 'lnknjuyd',
        txt: {
          value: 'our digital menu',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '700',
          },
        },
        name: 'our digital menu',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -29,
          angle: 0,
          scale: 65,
        },
      },
      {
        idx: 'lnkjgs6d',
        txt: {
          value: 'Scan to view',
          font: {
            size: '16', style: 'normal', family: 'Jost', weight: '700',
          },
        },
        name: 'Scan to view',
        type: 'txt',
        color: {
          fill: '#ffffff',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -38,
          angle: 0,
          scale: 78,
        },
      },
      {
        idx: 'lnbqaj6i',
        txt: {
          value: 'SCAN THE QR CODE WITH YOUR CAMERA',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '700',
          },
        },
        name: 'SCAN THE QR CODE WITH YOUR CAMERA',
        type: 'txt',
        color: {
          fill: '#ff6c52',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 24,
          angle: 0,
          scale: 74,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#7000fa',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701427960467,
  },
  /* ***************** */
  6: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circle',
            finder_frame: 'ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#232a3f',
          stroke: '#ecbca1',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 0,
          y: 1,
          angle: 0,
          scale: 31,
        },
      },
      {
        idx: 'lnligckf',
        txt: {
          value: 'More comfortable, faster, and user friendly',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'More comfortable, faster, and user friendly',
        type: 'txt',
        color: {
          fill: '#ecbca1',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 33,
          angle: 0,
          scale: 64,
        },
      },
      {
        idx: 'lnknjuyd',
        txt: {
          value: 'Digital menu',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'Digital menu',
        type: 'txt',
        color: {
          fill: '#ecbca1',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -29,
          angle: 0,
          scale: 35,
        },
      },
      {
        idx: 'lnbqaj6i',
        txt: {
          value: 'SCAN THE QR CODE TO VIEW THE MENU',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'SCAN THE QR CODE TO VIEW THE MENU',
        type: 'txt',
        color: {
          fill: '#ecbca1',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 40,
          angle: 0,
          scale: 88,
        },
      },
      {
        idx: 'lnli66hz',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#232c40',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 3,
          y: 82,
          angle: 0,
          scale: 154,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhp0p1',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#ecbca1',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 1,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhnmhx',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#ecbca1',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 7,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhna58',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#ecbca1',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 4,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhm8li',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#ecbca1',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -2,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlhgb3m',
        img: {
          file_name: 'shape82',
          isUserMedia: false,
        },
        name: 'shape82',
        type: 'img',
        color: {
          fill: '#ecbca1',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -5,
          y: -44,
          angle: 0,
          scale: 2,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnkjgs6d',
        txt: {
          value: 'RESTAURANT NAME',
          font: {
            size: '16', style: 'normal', family: 'Cormorant', weight: '400',
          },
        },
        name: 'RESTAURANT NAME',
        type: 'txt',
        color: {
          fill: '#ecbca1',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 1,
          y: -37,
          angle: 0,
          scale: 64,
        },
      },
      {
        idx: 'lnlhe3fs',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#232b3f',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 10,
          y: -90,
          angle: 0,
          scale: 185,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnlh6iqy',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#232a3f',
          stroke: '#ecbca1',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 20,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 1,
          angle: 0,
          scale: 35,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#23293f',
          stroke: '#c8af97',
          opacity: 0.5,
          strokeWidth: 10,
        },
        selected: false,
        transform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
        background: {
          pattern: 'weave',
        },
        savedColor: {
          fill: '#23293f',
          stroke: '#c8af97',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701428050618,
  },
  /* ***************** */
  7: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 341,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circles-solid',
            finder_frame: 'square-ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#ffffff',
          stroke: '#000000',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 0,
          y: -20,
          angle: 0,
          scale: 33,
        },
      },
      {
        idx: 'lnlmjbog',
        txt: {
          value: 'SCAN ME',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '700',
          },
        },
        name: 'SCAN ME',
        type: 'txt',
        color: {
          fill: '#098790',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -3,
          angle: 0,
          scale: 20,
        },
      },
      {
        idx: 'loedc381',
        txt: {
          value: 'PHONE: 415-555-1212',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: 'PHONE: 415-555-1212',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -31,
          y: 45,
          angle: 0,
          scale: 28,
        },
      },
      {
        idx: 'loeelkv8',
        img: {
          file_name: 'happyMeal',
          isUserMedia: false,
        },
        name: 'happyMeal',
        type: 'img',
        color: {
          fill: '#000000',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 1,
          y: 23,
          angle: 0,
          scale: 74,
          fillNotFit: false,
        },
      },
      {
        idx: 'lnkkjuak',
        txt: {
          value: 'RESTAURANT NAME',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '700',
          },
        },
        name: 'RESTAURANT NAME',
        type: 'txt',
        color: {
          fill: '#59bfc7',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -37,
          angle: 0,
          scale: 78,
        },
      },
      {
        idx: 'lnkn83i2',
        txt: {
          value: 'Your Logo',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '400',
          },
        },
        name: 'Your Logo',
        type: 'txt',
        color: {
          fill: '#59bfc7',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: -44,
          angle: 0,
          scale: 17,
        },
      },
      {
        idx: 'lo32lanv',
        img: {
          file_name: 'shape89',
          isUserMedia: false,
        },
        name: 'shape89',
        type: 'img',
        color: {
          fill: '#a7d8de',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 42,
          angle: 0,
          scale: 168,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#ffffff',
        },
        selected: true,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701429678087,
  },
  /* ***************** */
  8: {
    image: {
      size: {
        format: 'A6',
        portrait: true,
      },
      qrPngSize: {
        unit: 'px',
        width: 300,
        height: 300,
      },
      crop_marks: true,
      bleedOrTrim: 0,
      pngResolution: 300,
      crop_marks_color: '#FF0000',
    },
    content: [
      {
        qr: {
          tag: {
            attach: true,
            prefix: true,
            font_size: 7,
            prefix_short: false,
          },
          style: {
            point: 'default',
            finder_eye: 'circles-solid',
            finder_frame: 'square-ring',
          },
          margin: 2,
        },
        idx: 'zero',
        name: 'QR Code',
        color: {
          fill: '#d3cfc6',
          stroke: '#656d41',
          opacity: 0,
          fillOpacity: 1,
        },
        frame: {
          width: 0,
          active: true,
          offset: 0,
        },
        selected: false,
        transform: {
          x: 34,
          y: -38,
          angle: 0,
          scale: 23,
        },
      },
      {
        idx: 'loflamy5',
        txt: {
          value: 'Menu',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '700',
          },
        },
        name: 'Menu',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -24,
          y: -30,
          angle: 0,
          scale: 41,
        },
      },
      {
        idx: 'lnlmjbog',
        txt: {
          value: 'Special',
          font: {
            size: '16', style: 'normal', family: 'Noto Serif Display', weight: '700',
          },
        },
        name: 'Special',
        type: 'txt',
        color: {
          fill: '#111d23',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -22,
          y: -41,
          angle: 0,
          scale: 44,
        },
      },
      {
        idx: 'loeba24r',
        txt: {
          value: '101 Market Street, San Francisco, CA',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: '101 Market Street, San Francisco, CA',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: -15,
          y: 45,
          angle: 0,
          scale: 59,
        },
      },
      {
        idx: 'lnkkjuak',
        txt: {
          value: 'Scan Menu',
          font: {
            size: '16', style: 'normal', family: 'Playfair Display', weight: '700',
          },
        },
        name: 'Scan Menu',
        type: 'txt',
        color: {
          fill: '#656d41',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 34,
          y: -27,
          angle: 0,
          scale: 19,
        },
      },
      {
        idx: 'lnlor0m6',
        txt: {
          value: 'PHONE: 415-555-1212',
          font: {
            size: '16', style: 'normal', family: 'Victor Mono', weight: '400',
          },
        },
        name: 'PHONE: 415-555-1212',
        type: 'txt',
        color: {
          fill: '#000000',
          stroke: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 33,
          y: 45,
          angle: 0,
          scale: 28,
        },
      },
      {
        idx: 'lo32hbyj',
        img: {
          file_name: 'healtyLunch',
          isUserMedia: false,
        },
        name: 'healtyLunch',
        type: 'img',
        color: {
          fill: '#000000',
          stroke: '#000000',
          opacity: 1,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 1,
        },
        frame: {
          width: 1,
          active: false,
          offset: 1,
        },
        hidden: false,
        selected: false,
        transform: {
          x: 0,
          y: 8,
          angle: 0,
          scale: 78,
          fillNotFit: false,
        },
      },
      {
        idx: 'background',
        name: 'Background',
        color: {
          fill: '#d3cfc5',
        },
        selected: false,
        transform: null,
        background: {
          pattern: 'none',
        },
        savedColor: {
          fill: '#11053b',
          stroke: '#000000',
          opacity: 0.5,
          strokeWidth: 10,
        },
        savedTransform: {
          x: 0,
          y: 0,
          angle: 0,
          scale: 41,
        },
      },
    ],
    lastUpdated: 1701428779319,
  },
}
