/* eslint no-param-reassign: ["error", { "props": false }] */

import { mapGetters, mapActions } from 'vuex'
import {
  setColor,
  constructImage,
  selectElement,
  unselectElement,
  setItemFrame,
  displayGridLines,
  toggleHideElement,
  generatePages,
  generateDownload,
} from '@/qr/qrcode'

import { generateQRImage } from '@/qr/qrcodeGen'
import { transformImage } from '@/qr/DragRotateScale'

import optionsDefault from '@/qr/qr-default-options'
import {
  mergeDeep,
  createElementSVG,
} from '@/qr/utils'

import { createBackgroundPattern } from '@/qr/background'
import templateLibrary from '@/qr/qrTemplates'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'
import UPLOAD_IMAGE from '@/gql/mutation/uploadImage.gql'

export default {
  version: optionsDefault.version,
  data() {
    return {
      options: {
        document: {},
        print: JSON.parse(JSON.stringify(optionsDefault.print)),
        userMedia: [],
        selected: {},
        isSaved: true,
      },
      loaded: false,
      rerender: false,
      documents: {},
      documentIdx: null, // { idx: 0, isUserDoc: false },
      show_grid: false,
      grid_color: '#000000',
      grid_lines: 8,

      rename_layer: false,
      isFetching: true,
      isSaving: false,
      imperialFirst: false,
      lastUsedFont: {
        family: 'Arimo',
        style: 'normal',
        weight: '400',
      },
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      VUE_APP_IMG_BASE: 'main/VUE_APP_IMG_BASE',
    }),
    imageBleedOrTrim: {
      get() {
        return Math.round(this.options.document.image.bleedOrTrim * 100) / 100
      },
      set(v) {
        this.options.isSaved = false
        this.options.document.image.bleedOrTrim = v
      },
    },
    pageMargin: {
      get() {
        return Math.round(this.options.print.page.margin * 100) / 100
      },
      set(v) {
        this.options.print.page.margin = v
      },
    },
    pageImgGap: {
      get() {
        return Math.round(this.options.print.page.img_gap * 100) / 100
      },
      set(v) {
        this.options.print.page.img_gap = v
      },
    },
    invalidImageSize() {
      const size = this.options.document?.image?.size
      return !(size?.format || (size?.width && size?.height))
    },
    invalidPageSize() {
      return !(this.options.print.page.size.width && this.options.print.page.size.height)
    },
  },
  watch: {
    restaurant() {
      this.setData()
    },
    rerender(v) {
      if (v) {
        this.$nextTick(this.displayPreview)
      }
    },
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    onClickImage(image) {
      this.options.selected.layer.img.file_name = image.name
      this.options.selected.layer.img.isUserMedia = true
      this.rerender = true
      this.options.isSaved = false
    },

    deleteImage(image) {
      this.options.userMedia = this.options.userMedia.filter(i => i.id !== image.id)
      const layers = this.options.document.content.filter(i => i.img && i.img.file_name === image.name)
      layers.forEach(i => this.updateDocument(i, 'remove'))
      if (!image.newFile) {
        // TODO: remove the media file from the server
      }
    },

    async getMedia(i) {
      const item = i

      try {
        const response = await fetch(`${this.VUE_APP_IMG_BASE}/${item.linkName}`)

        try {
          const data = item.isSvg ? await response.text() : await response.blob()
          if (item.isSvg) {
            const svgString = data.replace(/(\r\n|\n|\r)/gm, '')
            const g = createElementSVG('g')
            g.innerHTML = svgString
            const svg = g.firstElementChild
            svg.removeAttribute('width')
            svg.removeAttribute('height')
            svg.setAttribute('preserveAspectRatio', 'xMinYMin meet')
            const title = createElementSVG('title')
            title.textContent = item.name
            svg.prepend(title)
            item.data = svg.outerHTML
          } else {
            const base64Data = await new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = () => resolve(reader.result)
              reader.onerror = error => reject(error)
            })
            item.data = base64Data
          }
        } catch {
          // do nothing
        }
      } catch {
        // do nothing
      }
    },

    async onInputImageFile(file) {
      const fileReader = new FileReader()

      if (file.type === 'image/svg+xml') {
        fileReader.readAsText(file)
      } else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        fileReader.readAsDataURL(file)
      } else {
        // should never get here
        alert('Invalid image file format. Only SVG, PNG, and JPEG files are allowed') // eslint-disable-line
      }
      fileReader.onload = () => {
        this.options.selected.layer.name = file.name
        this.options.selected.layer.img.file_name = file.name
        this.options.selected.layer.img.isUserMedia = true

        this.options.userMedia.unshift({
          name: file.name,
          id: Date.now().toString(36),
          isSvg: file.type === 'image/svg+xml',
          data: fileReader.result,
          newFile: file,
        })
        this.options.isSaved = false
        this.rerender = true
      }
    },

    mockData() {
      const content = 'https://sporkinc.com'
      const prefix = 'sample zone'
      return { content, prefix, tag: 1 }
    },

    async printPreview(root) {
      if (!this.qrData.length) return
      this.isGenerating = true
      this.$options.content = null
      this.$options.naturalSize = null
      const generateFunc = this.options.print.fileFormat === 'PDF'
        ? generatePages : generateDownload

      const pages = generateFunc(this.qrData, this.options)
      // eslint-disable-next-line
      for await (const page of pages) {
        if (page instanceof SVGElement) {
          const div = document.createElement('div')
          div.appendChild(page)
          root.appendChild(div)
        } else this.$options.content = page
      }
      this.$options.naturalSize = this.$options.content.naturalSize
      if (this.options.print.qrRemoveBG) this.onInputQrPngWidth()
      else this.getPngImageSize()
      this.isGenerating = false
    },

    async designPreview(root) {
      if (this.invalidImageSize) return
      root.append(await constructImage(this.mockData(), this.options, 'px', 'preview'))

      // re-select the element if there is one
      if (this.options.selected?.layer) {
        selectElement(
          this.options,
          root.querySelector(`g[data-idx='${this.options.selected.layer.idx}']`),
        )
      }
      if (this.show_grid) this.showGrid()
    },

    async displayPreview() {
      const root = this.$options.isPrintPreview ? this.$refs.previewPrint : this.$refs.preview
      if (root) {
        while (root.firstChild) root.lastChild.remove()

        if (this.$options.isPrintPreview) await this.printPreview(root)
        else await this.designPreview(root)
      }
      this.rerender = false
    },

    showGrid() {
      const svg = this.$refs.preview.querySelector('svg#canvas')
      if (svg) {
        const grid = svg.querySelector('#grid-lines')
        if (grid) grid.remove()
        if (this.show_grid) displayGridLines(svg, this.grid_lines, this.grid_color)
      }
    },
    swapColors() {
      if (this.options.selected?.layer.color) {
        const { color } = this.options.selected.layer;
        [color.fill, color.stroke] = [color.stroke, color.fill]
        setColor(this.options.selected.layer, this.options.selected.el)
        this.options.isSaved = false
      }
    },
    transform() {
      if (this.options.selected?.el) {
        if (this.options.selected.layer.background) this.updateBackground()
        else transformImage(this.options.selected.el, this.options.selected.layer.transform, 'preview')
        this.options.isSaved = false
      }
    },
    updateColor() {
      if (this.options.selected) {
        setColor(this.options.selected.layer, this.options.selected.el)
        this.options.isSaved = false
      }
    },
    updateItemFrame() {
      setItemFrame(this.options.selected.layer, this.options.selected.el)
      this.options.isSaved = false
    },

    changeFont() {
      const txt = this.options.selected?.layer?.txt
      if (txt) {
        const t = this.options.selected.el.querySelector('text')
        t.setAttribute('font-family', txt.font.family)
        t.setAttribute('font-style', txt.font.style)
        t.setAttribute('font-weight', txt.font.weight)
        this.options.isSaved = false
        this.lastUsedFont = txt.font
      }
    },
    updateTxt(value) {
      if (this.options.selected?.layer?.txt) {
        const g = this.options.selected.el
        const svg = g.querySelector('svg#frame>svg') || g.firstElementChild
        const t = svg.firstChild

        t.textContent = value
        this.options.selected.layer.txt.value = value
        this.options.selected.layer.name = value
        this.options.isSaved = false
      }
    },
    updateQR(type, idx) {
      if (type && this.options.selected && idx !== this.options.selected.layer.qr.style[type]) {
        this.options.selected.layer.qr.style[type] = idx
      }
      const newQR = generateQRImage(this.options.selected.layer, this.mockData())
      setColor(this.options.selected.layer, newQR)
      const g = this.options.selected.el
      const svg = g.querySelector('svg#frame>svg') || g.firstElementChild
      svg.replaceWith(newQR.firstChild)
      setItemFrame(this.options.selected.layer, this.options.selected.el)
      this.options.isSaved = false
    },
    updateBackground(idx) {
      if (this.options.selected) {
        if (idx && idx !== this.options.selected.layer.background.pattern) {
          this.options.selected.layer.background.pattern = idx
          if (idx === 'none') {
            this.options.selected.layer.savedTransform = this.options.selected.layer.transform
            this.options.selected.layer.savedColor = this.options.selected.layer.color
            this.options.selected.layer.transform = null
            this.options.selected.layer.color = { fill: this.options.selected.layer.savedColor.fill }
          } else {
            if (this.options.selected.layer.savedTransform) {
              this.options.selected.layer.transform = this.options.selected.layer.savedTransform
              delete this.options.selected.savedTransform
            }
            if (this.options.selected.layer.savedColor) {
              this.options.selected.layer.savedColor.fill = this.options.selected.layer.color.fill
              this.options.selected.layer.color = this.options.selected.layer.savedColor
              delete this.options.selected.savedColor
            }
          }
        }
        const texture = this.options.selected.el.querySelector('g#texture')
        if (texture) texture.remove()
        if (this.options.selected.layer.background.pattern !== 'none') {
          const newTexture = createBackgroundPattern(this.options.selected.layer).querySelector('g#texture')
          this.options.selected.el.appendChild(newTexture)
          setColor(this.options.selected.layer, this.options.selected.el)
        }
        this.options.isSaved = false
      }
    },
    addLayer(type) {
      const randomInt = max => Math.floor(Math.random() * max)
      const y = randomInt(18) - 9

      const layer = {
        idx: Date.now().toString(36),
        hidden: false,
        transform: {
          angle: 0,
          x: randomInt(36) - 18,
          y: (y < 0 ? -33 : 33) + y,
          scale: 50,
        },
        frame: { active: false, width: 1, offset: 1 },

        selected: true,
        type,
      }
      switch (type) {
        case 'img':
          layer.img = { file_name: 'square' }
          layer.name = 'square'
          layer.color = { opacity: 1 }
          layer.transform.fillNotFit = false
          break
        case 'txt':
          layer.txt = {
            value: '',
            font: { ...this.lastUsedFont },
          }
          layer.color = {
            fill: '#000000',
            stroke: '#ffffff',
            fillOpacity: 1.0,
            strokeOpacity: 1.0,
            opacity: 1.0,
            strokeWidth: 0,
          }
          this.rename_layer = true
          break
        default:
          break
      }
      if (this.options.selected?.layer?.selected) this.options.selected.layer.selected = false

      // insert the new layer as the second element after the QR  code
      this.options.document.content.splice(1, 0, layer)
      this.rerender = true
      this.options.isSaved = false
    },

    updateDocument(layer, action, from, to) {
      let arr
      const el = this.$refs.preview.querySelector(`g[data-idx='${layer.idx}']`)
      switch (action) {
        case 'select':
          if (layer.selected) {
            this.rename_layer = !layer.background
          } else {
            this.rename_layer = false
            selectElement(this.options, el)
          }
          break
        case 'hide':
          if (this.options.selected?.el === el) {
            unselectElement(this.options.selected)
          }
          layer.hidden = !layer.hidden
          toggleHideElement(el, layer.hidden)
          this.options.isSaved = false
          break
        case 'move':
          arr = this.options.document.content
          arr.splice(to, 0, arr.splice(from, 1)[0])
          this.rerender = true
          this.options.isSaved = false
          break
        case 'remove':
          if (this.options.selected?.el === el) {
            unselectElement(this.options.selected)
          }
          el.remove()
          this.options.document.content = this.options.document.content.filter(d => d.idx !== layer.idx)
          this.options.isSaved = false
          break
        default:
          break
      }
    },
    async setData() {
      if (!this.restaurant || this.loaded) {
        return
      }
      this.isFetching = true
      this.imperialFirst = this.restaurant.measurementUnits !== 'Metric'

      // eslint-disable-next-line no-undef
      const qrCodeCustomization = structuredClone(this.restaurant.qrSettings)
      if (qrCodeCustomization?.version === this.$options.version) {
        this.options.print = mergeDeep(this.options.print, qrCodeCustomization.print)
        this.documentIdx = qrCodeCustomization.documentIdx
        if (qrCodeCustomization.documents) this.documents = qrCodeCustomization.documents
        if (qrCodeCustomization.userMedia) this.options.userMedia = qrCodeCustomization.userMedia
      }

      await Promise.all(this.options.userMedia.map(i => this.getMedia(i)))

      if (this.documentIdx) {
        const src = (this.documentIdx.isUserDoc ? this.documents : templateLibrary)[this.documentIdx.idx]
        if (src) this.$set(this.options, 'document', this.documentIdx.isUserDoc ? src : JSON.parse(JSON.stringify(src)))
      }

      if (Object.keys(this.options.document).length) {
        await this.$nextTick()
        this.rerender = true
      } else if (!this.$options.isPrintPreview) {
        this.isModalQrTemplate = true
      }

      this.isFetching = false
      this.loaded = true
      this.options.isSaved = true
    },
    async onClickSave(isToast = true) {
      this.isSaving = true

      if (!this.options.isSaved) {
        this.options.document.lastUpdated = Date.now()
      }

      if (!this.options.isSaved && !this.documentIdx.isUserDoc) {
        this.documentIdx = { idx: Date.now().toString(36), isUserDoc: true }
        this.documents[this.documentIdx.idx] = this.options.document
      }

      const newMedia = this.options.userMedia.filter(i => i.newFile)
      for (let i = 0; i < newMedia.length; i += 1) {
        const media = newMedia[i]
        const formData = new FormData()
        formData.append('file', media.newFile)
        formData.append('parentNodeType', 'Editor')

        const response = await this.$apollo.mutate({ // eslint-disable-line no-await-in-loop
          mutation: UPLOAD_IMAGE,
          variables: {
            imageFile: media.newFile,
          },
        })
        media.linkName = response.data.uploadImage.linkName
        delete media.newFile
      }

      const qrCodeCustomization = {
        version: this.$options.version,
        print: this.options.print,
        documentIdx: this.documentIdx,
        documents: this.documents,
        userMedia: this.options.userMedia.map(i => ({ ...i, data: null })),
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            qrSettings: qrCodeCustomization,
          },
        })
        this.updateRestaurant({ qrSettings: qrCodeCustomization })

        this.options.isSaved = true
        this.isLoadedFromTemplate = false

        if (isToast) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Success'),
              text: this.$t('notificationSuccessSaved'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
    async loadTemplate(newTemplate) {
      this.$set(this.options, 'document', newTemplate.template)
      this.documentIdx = { isUserDoc: newTemplate.isUserDoc, idx: newTemplate.idx }
      unselectElement(this.options.selected)
      await this.$nextTick()
      this.rerender = true
      this.isLoadedFromTemplate = true
      this.options.isSaved = true
    },
    onClickLibraryImage(index) {
      this.options.selected.layer.img.file_name = index
      this.options.selected.layer.img.isUserMedia = false
      this.rerender = true
      this.options.isSaved = false
    },
    getStepForInput(type) {
      const dest = type === 'page' ? this.options.print.page : this.options.document.image
      const unit = type === 'png' ? this.options.document.image.qrPngSize.unit : dest?.size.unit
      return { in: 0.125, cm: 0.1 }[unit] || 1
    },
  },
}
