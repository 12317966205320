import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      zones: 'floorPlan/zones',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
    }),
    isCreateZoneDisabled() {
      return (this.isSubscriptionInactive && this.zones.length >= 1)
        || (this.subscription
          && this.subscription.name !== 'Premium'
          && this.zones.length >= this.subscription.productDetails.restrictions?.zonesAmount
        )
    },
  },
}
