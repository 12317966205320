<template>
  <b-modal
    id="modal-qr-print"
    :visible="value"
    :title="isFetching ? '' : modalErrorTitle || !isFetching && $t(options.print.fileFormat === 'PDF' ? 'Open' : 'Download')+' '+options.print.fileFormat"
    centered
    :size="options.document.content && zones.length ? 'xl' : 'md'"
    :scrollable="modalErrorTitle ? false : true"
    :hide-footer="modalErrorTitle ? false : true"
    @ok="$router.push({ name: pageRedirect })"
  >
    <template v-if="value">
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-row
          v-if="modalErrorText"
          class="py-1 align-items-start"
        >
          <b-col>
            <i18n :path="modalErrorText">
              <template v-slot:slot>
                <br><br>
              </template>
            </i18n>
          </b-col>
        </b-row>

        <b-row
          v-else-if="options.document.content"
          class="py-1 align-items-start"
        >
          <b-col
            cols="3"
            style="position: sticky; top: 1rem"
          >
            <b-form-group
              v-if="zoneCodes.length > 1"
              :label="`${$t('qrModalPrintSelectZones')}`"
              class="mb-2"
            >
              <b-form-checkbox-group
                v-model="selectedZones"
                text-field="text"
                size="sm"
                :options="zoneCodes.map(i => ({ text: i.name, value: i }))"
                @change="onCheckboxZones"
              />
            </b-form-group>

            <h5 class="mb-2">
              {{ displayImageSize() }}
              <small v-if="zoneId"><b-link href="/restaurant/qr">{{ $t('qrLinkNameChange') }}</b-link></small>
            </h5>
            <h5>
              {{ $t('File Format') }}
            </h5>
            <b-form-radio-group
              v-model="options.print.fileFormat"
              :options="['PDF', 'PNG', 'SVG']"
              class="mb-2"
              size="sm"
              @change="rerender = true"
            />
            <b-form-checkbox
              v-model="options.document.content[0].qr.tag.attach"
              size="sm"
              @change="rerender = true"
            >
              <small>{{ $t('qrLabelTag') }}</small>
            </b-form-checkbox>
            <b-form-checkbox
              v-if="options.document.content[0].qr.tag.attach"
              v-model="options.document.content[0].qr.tag.prefix"
              size="sm"
              inline
              class="ml-1"
              @change="rerender = true"
            >
              <small>{{ $t('qrLabelZone') }}</small>
            </b-form-checkbox>
            <b-form-checkbox
              v-if="options.document.content[0].qr.tag.attach && options.document.content[0].qr.tag.prefix"
              v-model="options.document.content[0].qr.tag.prefix_short"
              inline
              size="sm"
              @change="rerender = true"
            >
              <small>{{ $t('qrLabelAbbreviation') }}</small>
            </b-form-checkbox>
            <b-form-group
              v-if="options.document.content[0].qr.tag.attach"
              :label="`${$t('qrLabelTagFontSize')}`"
              label-size="sm"
              class="ml-1 mt-50 w-50"
            >
              <b-form-input
                v-model.number="options.document.content[0].qr.tag.font_size"
                type="number"
                min="1"
                max="999"
                size="sm"
                @change="rerender = true"
              />
            </b-form-group>
            <h5 class="mt-2 mb-0">
              {{ options.print.fileFormat }} {{ $t('Settings') }}
            </h5>
            <div
              v-if="options.print.fileFormat ==='PDF'"
              id="pdf-settings"
            >
              <paper-format-selector
                v-model="options.print.page.size"
                :label="`${$t('qrLabelPageSize')}`"
                :imperial-first="imperialFirst"
                size="sm"
                class="mt-1"
                @change="onChangePageFormat"
              />
              <b-form-group
                :label="`${$t('qrLabelImageIndent')}`"
                label-size="sm"
                class="mt-2 mb-0"
              >
                <b-input-group
                  size="sm"
                  :append="options.document.image.size.unit"
                >
                  <b-form-input
                    v-model.number="imageBleedOrTrim"
                    type="number"
                    :step="getStepForInput('image')"
                    @change="rerender = true"
                  />
                </b-input-group>
              </b-form-group>
              <div
                v-if="options.document.image.bleedOrTrim !== 0"
                class="d-flex align-items-center"
              >
                <div
                  class="d-flex align-items-center text-nowrap"
                  style="min-height: 38px"
                >
                  <b-form-checkbox
                    v-model="options.document.image.crop_marks"
                    size="sm"
                    @change="rerender = true"
                  >
                    <small>{{ $t('qrLabelCropMarks') }}</small>
                  </b-form-checkbox>
                </div>
                <b-form-input
                  v-if="options.document.image.crop_marks"
                  v-model="options.document.image.crop_marks_color"
                  type="color"
                  size="sm"
                  class="ml-1"
                  @change="rerender = true"
                />
              </div>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group
                    :label="`${$t('qrLabelMargin')}`"
                    class="mb-0 text-nowrap"
                    label-size="sm"
                  >
                    <b-input-group
                      size="sm"
                      :append="options.print.page.size.unit"
                    >
                      <b-form-input
                        v-model.number="pageMargin"
                        type="number"
                        min="0"
                        max="999"
                        :step="getStepForInput('page')"
                        @change="rerender = true"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="`${$t('qrLabelImageGap')}`"
                    label-size="sm"
                    class="mb-0 text-nowrap"
                  >
                    <b-input-group
                      size="sm"
                      :append="options.print.page.size.unit"
                    >
                      <b-form-input
                        v-model.number="pageImgGap"
                        type="number"
                        size="sm"
                        min="0"
                        max="999"
                        :step="getStepForInput('page')"
                        @change="rerender = true"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                :label="`${$t('qrLabelCopies')}`"
                label-size="sm"
                class="mb-0 mt-2 w-40"
              >
                <b-form-input
                  v-model.number="options.print.copies"
                  type="number"
                  size="sm"
                  min="1"
                  max="999"
                  @change="rerender = true"
                />
              </b-form-group>
            </div>
            <b-form-checkbox
              v-if="options.print.fileFormat !== 'PDF'"
              v-model="options.print.qrRemoveBG"
              size="sm"
              class="mt-1"
              @change="rerender = true"
            >
              <small>{{ $t('qrRemoveBackgroundImage') }}</small>
            </b-form-checkbox>
            <b-form-checkbox
              v-if="options.print.fileFormat !== 'PDF' && options.print.qrRemoveBG"
              v-model="options.print.qrRemoveStylizing"
              size="sm"
              class="ml-1"
              @change="rerender = true"
            >
              <small>{{ $t('qrRemoveStylizing') }}</small>
            </b-form-checkbox>
            <b-form-group
              v-if="options.print.fileFormat === 'PNG' && options.print.qrRemoveBG"
              size="sm"
              label-size="sm"
              class="ml-1 mt-50 w-50"
              :label="`QR ${$t('qrLabelImageSize')}`"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="options.document.image.qrPngSize.width"
                  type="number"
                  min="0"
                  max="999"
                  size="sm"
                  :step="getStepForInput('png')"
                  @input="onInputQrPngWidth"
                />
                <b-form-input
                  :value="options.document.image.qrPngSize.height"
                  disabled
                  type="number"
                  size="sm"
                />
                <template #append>
                  <b-dropdown
                    :text="options.document.image.qrPngSize.unit"
                    right
                    size="sm"
                    variant="outline-secondary"
                  >
                    <b-dropdown-item
                      v-for="unit in optionsUnit"
                      :key="unit"
                      @click.stop="onInputPngUnit(unit)"
                    >
                      {{ unit }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-input-group>
            </b-form-group>

            <b-form-group
              v-if="EnableShowResolution"
              :label="`${$t('Resolution')}`"
              class="mb-0 mt-2"
            >
              <b-form-radio-group
                v-model="options.document.image.pngResolution"
                :options="[
                  { value: 72, html: '<small style=\'width: 4rem; display:inline-block\'>72 dpi</small>'},
                  { value: 300, html: '<small>300 dpi</small>'},
                ]"
                size="sm"
                @change="getPngImageSize"
              />
              <b-form-radio-group
                v-model="options.document.image.pngResolution"
                :options="[
                  { value: 600, html: '<small style=\'width: 4rem; display:inline-block\'>600 dpi</small>'},
                  { value: 1200, html: '<small>1200 dpi</small>'},
                ]"
                size="sm"
                class="mb-1"
                @change="getPngImageSize"
              />
              <small>{{ $t('File Size') }}: {{ ImageInPixels }}</small>
            </b-form-group>
            <div class="d-flex align-items-center mt-3">
              <b-button
                variant="primary"
                size="sm"
                @click="downloadFile"
              >
                <b-spinner
                  v-if="isGenerating || $options.content === null"
                  class="d-flex"
                  small
                />
                <template v-else>
                  {{ options.print.fileFormat === 'PDF' ? $t('Open') : $t('Download') }} {{ options.print.fileFormat }}
                </template>
              </b-button>
              <b-button
                variant="outline-primary"
                class="ml-2"
                size="sm"
                @click="$emit('input', false)"
              >
                <b-spinner
                  v-if="isGenerating || $options.content === null"
                  class="d-flex"
                  small
                />
                <template v-else>
                  {{ $t('Cancel') }}
                </template>
              </b-button>
            </div>
          </b-col>
          <b-col cols="9">
            <div
              id="preview-print"
              ref="previewPrint"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BLink,
  BModal,
  BOverlay,
  BRow,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import mixinQr from '@/mixins/qr'
import { makeFile } from '@/qr/qrcode'
import {
  getBoxSize,
  convertSize,
  roundSize,
  validUnits,
} from '@/qr/utils'
import PaperFormatSelector from '@/components/PaperFormatSelector.vue'

export default {
  name: 'ModalQrPrint',
  isPrintPreview: true,
  components: {
    BFormGroup,
    BCol,
    BRow,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BOverlay,
    BButton,
    BSpinner,
    PaperFormatSelector,
  },
  mixins: [
    mixinQr,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    zoneId: {
      type: [Number, String], // TODO: please, 1 type only
      required: false,
      default: null,
    },
  },
  data() {
    return {
      qrData: [],
      zoneCodes: [],
      pageRedirect: 'qr',
      modalErrorTitle: null,
      modalErrorText: null,
      selectedZones: [],
      isGenerating: false,
      imageComputedSize: null,
      optionsUnit: [],
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      zones: 'floorPlan/zones',
      isInfo: 'main/isInfo',
      isZonesFetched: 'floorPlan/isZonesFetched',
    }),
    ImageInPixels() {
      if (!this.imageComputedSize) {
        return ' ... loading'
      }

      const width = parseInt(this.imageComputedSize.width, 10)
      const height = parseInt(this.imageComputedSize.height, 10)
      return `${width} × ${height} px`
    },
    EnableShowResolution() {
      const unit = this.options.print.qrRemoveBG
        ? this.options.document.image.qrPngSize.unit
        : this.options.document.image.size.unit

      return this.options.print.fileFormat === 'PNG' && unit !== 'px'
    },
  },
  watch: {
    isZonesFetched(v) {
      if (v) this.initModal()
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      if (this.value) {
        this.initModal()
      }
    })
    this.$root.$on('bv::modal::hide', () => {
      this.$emit('input', false)
    })

    this.optionsUnit = validUnits(this.imperialFirst)
  },
  methods: {
    ...mapActions({
      getZones: 'floorPlan/getZones',
      shortenPmenusRequest: 'main/shortenPmenusRequest',
    }),
    async initModal() {
      if (!this.isZonesFetched) {
        this.getZones()
        return
      }
      this.loaded = false
      this.isFetching = true

      if (this.zones.length) {
        this.zoneCodes = await this.getZoneCodes()
        this.selectedZones = this.zoneId ? this.zoneCodes.filter(z => z.id === this.zoneId) : this.zoneCodes

        await this.setData()
        this.onCheckboxZones()
        this.modalErrorTitle = !this.options.document.content && this.$t('qrModalPrintNoTemplateTitle')
        this.modalErrorText = !this.options.document.content && 'qrModalPrintNoTemplateText'
      } else {
        this.pageRedirect = 'zones'
        this.modalErrorTitle = this.$t('qrModalPrintNoZoneTitle')
        this.modalErrorText = 'qrModalPrintNoZoneText'
      }
      this.isFetching = false
    },

    async getZoneCodes() {
      const rid = this.restaurant.id
      return Promise.all(this.zones.map(async z => ({
        id: z.id,
        name: z.name,
        code: !z.tables.length && await this.shortenPmenusRequest([rid, z.id]),
        tables: await Promise.all(z.tables.map(async t => ({
          name: t.name,
          code: await this.shortenPmenusRequest([rid, z.id, t.id]),
        }))),
      })))
    },

    onCheckboxZones() {
      this.options.zipFname = this.selectedZones.length > 1 && this.restaurant.name
      this.qrData = this.selectedZones.map(z => this.getQrData(z)).flat(1)
      this.rerender = true
    },

    getQrData(zone) {
      const base = process.env.VUE_APP_ORIGIN_CLIENT

      return zone.tables.length
        ? zone.tables.map(i => ({ content: `${base}/${i.code}`, prefix: zone.name, tag: i.name }))
        : [{ content: `${base}/${zone.code}`, prefix: zone.name, tag: null }]
    },

    displayImageSize() {
      const { size } = this.options.document.image

      const v = size.unit ? size : getBoxSize(size)

      return `${this.$t('Image')}: ${v.format || 'Custom'} (${roundSize(v.width, v.unit)}
      × ${roundSize(v.height, v.unit)} ${v.unit})`
    },

    onChangePageFormat(event) {
      if (event?.unitChanged) {
        this.options.print.page.margin = convertSize(
          this.options.print.page.margin,
          event.unitChanged.old,
          event.unitChanged.new,
        )
        this.options.print.page.img_gap = convertSize(
          this.options.print.page.img_gap,
          event.unitChanged.old,
          event.unitChanged.new,
        )
      }
      if (event?.sizeChanged) {
        this.rerender = true
        this.options.isSaved = false
      }
    },
    onInputQrPngWidth() {
      let h = this.options.document.image.qrPngSize.width
      if (this.$options.naturalSize) {
        h *= this.$options.naturalSize.height / this.$options.naturalSize.width
        if (this.options.document.image.qrPngSize.unit === 'px') h = parseInt(h, 10)
      }
      this.options.document.image.qrPngSize.height = h
      this.getPngImageSize()
    },
    onInputPngUnit(unit) {
      if (unit !== this.options.document.image.qrPngSize.unit) {
        const size = getBoxSize(this.options.document.image.qrPngSize, unit, 'round')
        if (unit === 'px') {
          size.width = parseInt(size.width, 10)
          size.height = parseInt(size.height, 10)
        }
        this.options.document.image.qrPngSize = size
      }
    },

    getPngImageSize() {
      const size = this.options.print.qrRemoveBG
        ? this.options.document.image.qrPngSize : this.options.document.image.size

      this.imageComputedSize = getBoxSize(size, 'px', 'round', this.options.document.image.pngResolution)
    },

    async downloadFile() {
      this.isGenerating = true
      this.$options.content.size = this.imageComputedSize
      await this.$nextTick()
      await makeFile(this.$options.content, this.options)
      this.$emit('input', false)
      this.onClickSave(/* toast */ false)
      this.isGenerating = false
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/vue/libs/vue-select.scss'

#modal-qr-print
  .modal-xl
    width: 94%
    max-width: 94%
    height: 94%
  .modal-content
    min-height: 100%
  .custom-radio.b-custom-control-sm,
  .custom-checkbox.b-custom-control-sm
    .custom-control-label
      &::before,
      &::after
        left: 0
  #preview-print
    display: flex
    flex-wrap: wrap
    margin-bottom: -1rem
    div
      width: 300px
      margin-right: 1rem
      margin-bottom: 1rem
      outline: 1px dotted $gray-600
      outline-offset: 0.5px
      pointer-events: none
</style>
