export default {
  version: 1,
  print: {
    // print settings
    page: {
      // either a format string, or width and height.  If format is give,
      // all dimentions and unit will be calculated and overwritten
      size: {
        format: 'A4',
        portrait: true, // only used with the format, ignored otherwise
      },
      margin: 0,
      img_gap: 0, // between printed images.
    },
    copies: 1,
    fileFormat: 'PDF',
    qrRemoveBG: false,
    qrRemoveStylizing: false,
  },
}
